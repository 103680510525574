<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div  class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">Accounts</h4>
      </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="search-wrapper">

            <el-input
              placeholder="Search by broker"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input>

            <!-- <el-input
              v-model="listQuery.search"
              placeholder="Search"
              clearable
            />
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch()"
              circle
            ></el-button> -->
          </div>
        </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button v-if="getRoleSlug == 'admin'" type="primary" @click="handleDownload()">Download</el-button>
        </div>
      </el-col>
    </el-row>
    </div>
    <br>
    <br>
    <el-tabs class="marketwatch_tab" type="card" @tab-click="handleTabClick" v-model="currentTab">
      <el-tab-pane label="Brokerage share to broker  " name="brokerage_share_to_broker">
          <div style="padding-top: 60px">
            <Table
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listData"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionShowVisibility="false"
              :showSummary="false"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="Brokerage charge to broker" name="brokerage_charge_to_broker">
          <div style="padding-top: 60px">
            <Table
              tableKey="closePositions"
              :tableConfig="charge_config"
              :tableData="listData"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionShowVisibility="false"
              :showSummary="false"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
            />
          </div>
        </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Table from "@/components/Table/Table.vue";
//import DateRangePicker from "@/components/DatePicker/DateRangePicker.vue";
import { fetchList, downloadAcountList } from "@/api/account";
import { mapGetters } from "vuex";

export default {
  name: "ListClosePositions",
  components: {
    Table
  },
  data() {
    return {
      loading: false,
      currentTab:"brokerage_share_to_broker",
      type:"brokerage_share_to_broker",
      config: [
        {
          label: "Receivable / Payable",
          prop: "receivable_payable",
          className: "blueFont",
          width: "200"
        },
        {
          label: "Broker",
          prop: "broker",
          className: "blueFont",
          width: "150"

        },
        {
          label: "SUM of Client PL",
          prop: "sum_client_pl",
          className: "blueFont",
          width: "200"
        },
        {
          label: "SUM of Client Brokerage",
          prop: "sum_client_brokerage",
          width: "240",
          className: "blueFont",
        },
        {
          label: "SUM of Client Net",
          prop: "sum_client_net",
          className: "blueFont",
          width: "200"

        },
        {
          label: "PL Share",
          prop: "pl_share",
          className: "blueFont",
          width: "150"

        },
        {
          label: "Brokerage Share",
          prop: "brokerage_share",
          className: "blueFont",
          width: "200"

        },
        {
          label: "Net Share",
          prop: "net_share",
          className: "blueFont",
          width: "200"

        },
      ],

      charge_config: [
        {
          label: "Receivable / Payable",
          prop: "receivable_payable",
          className: "blueFont",
          width: "200"
        },
        {
          label: "Broker",
          prop: "broker",
          className: "blueFont",
          width: "150"

        },
        {
          label: "SUM of Client PL",
          prop: "sum_client_pl",
          className: "blueFont",
          width: "200"
        },
        // {
        //   label: "SUM of Client Brokerage",
        //   prop: "sum_client_brokerage",
        //   width: "240",
        //   className: "blueFont",
        // },
        // {
        //   label: "SUM of Client Net",
        //   prop: "sum_client_net",
        //   className: "blueFont",
        //   width: "200"

        // },
        {
          label: "PL Share",
          prop: "pl_share",
          className: "blueFont",
          width: "150"

        },
        {
          label: "Brokerage Charge",
          prop: "brokerage_share",
          className: "blueFont",
          width: "200"

        },
        {
          label: "Net Share",
          prop: "net_share",
          className: "blueFont",
          width: "200"

        },
      ],
      listData: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        type:"",
        start_date: null,
        end_date: null,
      },
      data: {
        data: {
          item: [
            {
              receivable_payable: "Rs.0 Receive from Parent Admin.",
              broker: "31: superadmin",
              sum_client_pl: "82302",
              sum_client_brokerage: "19559.13",
              sum_client_net: "62742.87",
              pl_share: "0",
              brokerage_share: "0",
              net_share: "0",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getList();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.listQuery.type = this.type
      fetchList(this.listQuery).then((response) => {
        this.listData = response.data;
        this.loading = false;
      });
    },

    handleTabClick(tab) {
          console.log(tab.index);
          if (tab.index == 0) {
            this.type = 'brokerage_share_to_broker'
          } else if (tab.index == 1) {
            this.type = 'brokerage_charge_to_broker'
          } 

          console.log("jfhjfhfh   hhgyy  ",this.type);

          this.getList();
      },
    // on date range update
    onUpdatedExpireDate(value) {
      this.listQuery.start_date = value.start;
      this.listQuery.end_date = value.end;
    },
    handleDownload() {
      downloadAcountList(this.listQuery).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
      })
      .catch((err) => {
        console.log(err);
      });
    },
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
  },
};
</script>
